import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {LocationForm} from "@/modules/locations/interfaces";

export const createLocation = async ({commit}: ActionContext<any, any>, location: LocationForm) => {
    try {
        await erpApi.post('/locations', location)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateLocation = async ({commit}: ActionContext<any, any>, location: LocationForm) => {
    try {
        await erpApi.put('/locations/' + location.id, location)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getLocation = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/locations/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
