import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_options_modal = _resolveComponent("options-modal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.authStatus === 'authenticating')
      ? (_openBlock(), _createElementBlock("h1", _hoisted_1, _toDisplayString($setup.authStatus), 1))
      : (_openBlock(), _createBlock(_component_router_view, {
          key: _ctx.$router.patch + $setup.uuid()
        })),
    _createVNode(_component_options_modal)
  ], 64))
}