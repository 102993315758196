import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {PaymentForm} from "@/modules/payments/interfaces"

export const createPayment = async ({commit}: ActionContext<any, any>, payment: PaymentForm) => {
    try {
        await erpApi.post('/payments', payment)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updatePayment = async ({commit}: ActionContext<any, any>, payment: PaymentForm) => {
    try {
        await erpApi.put('/payments/' + payment.id, payment)
        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getPayment = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/payments/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getStarter = async ({commit}: ActionContext<any, any>, payment: PaymentForm) => {
    try {
        const response = await erpApi.get(`/payments/starter?starterType=${payment.starterType}&starterId=${payment.starterId}`)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
