export default [
    {
        path: '/inventory_configuration',
        name: 'inventory_configuration',
        component: () => import('./../views/List.vue'),
        children: [
            {
                path: '/inventory_configuration/categories',
                name: 'inventory_configuration.categories',
                component: () => import('./../views/Categories.vue'),
            },
            {
                path: '/inventory_configuration/measurement_units',
                name: 'inventory_configuration.measurement_units',
                component: () => import('./../views/MeasurementUnits.vue'),
            }
        ]
    }
];
