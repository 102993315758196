<template>
    <div class="input-group">
        <div class="input-group-prepend">
            <span class="input-group-text"><i :class="icon || ''"></i></span>
        </div>

        <input
            type="text"
            ref="root"
            :value="modelValue"
            :id="attributes.id"
            autocomplete="off"
            :name="attributes.name || ''"
            :data-rule-required="attributes.required"
            :disabled="attributes.disabled"
            :class="classv"
            v-on:input="updateValue($event.target.value)"
            :aria-required="attributes.required"
            :readonly="readonly"
        />
    </div>
</template>

<script>
import { nextTick, onMounted, onUnmounted, ref, watch } from "vue";

export default {
    props: {
        modelValue: { required: true, default: "" },
        config: { type: Object, required: true },
        icon: { type: String, default: "fa fa-calendar-minus-o" },
        classv: { type: String, default: "form-control" },
        readonly: { required: false, default: false },
        attributes: {
            type: Object,
            default: function () {
                return {};
            },
        },
        debug: { type: Boolean, default: false },
    },
    setup(props, { emit }) {
        const root = ref(null);
        let rootPicker = null;

        function init() {
            let ele = window.$(root.value);

            ele.daterangepicker(props.config).on("apply.daterangepicker", function (ev, picker) {
                let format = typeof props.config.locale != "undefined" ? props.config.locale.format : "DD/MM/YYYY";

                rootPicker = picker;

                if (
                    props.config.singleDatePicker &&
                    !window._.isUndefined(props.config.singleDatePicker) &&
                    props.config.singleDatePicker == false
                ) {
                    let fecha = picker.startDate.format(format) + " - " + picker.endDate.format(format);
                    updateValue(fecha);
                } else {
                    updateValue(picker.startDate.format(format));
                }
            });

            rootPicker = ele.data("daterangepicker");
        }

        function setMinDate(val) {
            if (rootPicker) {
                rootPicker.setMinDate(val);
            }
        }

        function destroy() {
            window.$(root.value).daterangepicker("destroy");
        }

        function updateValue(value) {
            emit("update:modelValue", value);
        }

        onMounted(() => {
            init();
        });

        onUnmounted(() => {
            window.$(root.value).daterangepicker("destroy");
        });

        watch(
            () => props.modelValue,
            (value) => {
                nextTick(function () {
                    window.$(root.value).val(value);
                    window.$(root.value).trigger("change");
                });
            }
        );

        return {
            root,
            init,
            setMinDate,
            destroy,
            updateValue,
        };
    },
};
</script>
