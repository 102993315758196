import { ActionContext, createStore } from "vuex";
import auth from "@/modules/auth/store";
import companies from "@/modules/companies/store";
import locations from "@/modules/locations/store";
import accountingCenters from "@/modules/accountingCenters/store";
import providers from "@/modules/providers/store";
import accountingAccounts from "@/modules/accountingAccounts/store";
import pettyCash from "@/modules/pettyCash/store";
import providerAdvances from "@/modules/providerAdvances/store";
import payments from "@/modules/payments/store";
import inventory_configuration from "@/modules/inventory_configuration/store";
import accounting_configuration from "@/modules/accounting_configuration/store";
import purchaseInvoices from "@/modules/purchaseInvoices/store";
import purchaseOrders from "@/modules/purchaseOrders/store";
import items from "@/modules/items/store";
import inventoryEntries from "@/modules/inventoryEntries/store";

export default createStore({
    state: {
        topbarMenuName: "",
    },
    mutations: {
        topbarMenuName: (state: { topbarMenuName: string | null }, name: string) => {
            state.topbarMenuName = name;
        },
    },
    actions: {
        topbarMenuNameChanged: async ({ commit }: ActionContext<any, any>, name: string) => {
            commit("topbarMenuName", name);
        },
    },
    modules: {
        auth,
        companies,
        locations,
        accountingCenters,
        providers,
        accountingAccounts,
        pettyCash,
        providerAdvances,
        payments,
        inventory_configuration,
        purchaseInvoices,
        purchaseOrders,
        items,
        accounting_configuration,
        inventoryEntries,
    },
});
