import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {TaxForm} from "@/modules/accounting_configuration/interfaces";

export const createTax = async ({commit}: ActionContext<any, any>, tax: TaxForm) => {
    try {
        await erpApi.post('taxes', tax)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateTax = async ({commit}: ActionContext<any, any>, tax: TaxForm) => {
    try {
        await erpApi.put('/taxes/' + tax.id, tax)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getTax = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/taxes/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
