import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {ProviderAdvanceForm} from "@/modules/providerAdvances/interfaces";

export const createProviderAdvance = async ({commit}: ActionContext<any, any>, providerAdvance: ProviderAdvanceForm) => {
    try {
        await erpApi.post('/provider_advances', providerAdvance)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateProviderAdvance = async ({commit}: ActionContext<any, any>, providerAdvance: ProviderAdvanceForm) => {
    try {
        await erpApi.put('/provider_advances/' + providerAdvance.id, providerAdvance)
        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getProviderAdvance = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/provider_advances/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
