export default [
    {
        path: '/accounting_accounts',
        name: 'accounting_accounts',
        component: () => import('./../views/List.vue'),
    },
    {
        path: '/accounting_accounts/create',
        name: 'accounting_accounts.create',
        component: () => import('./../views/Create.vue'),
    },
    {
        path: '/accounting_accounts/edit/:id?',
        name: 'accounting_accounts.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    }
];
