export default [
    {
        path: '/providers',
        name: 'providers',
        component: () => import('./../views/List.vue'),
    },
    {
        path: '/providers/create',
        name: 'providers.create',
        component: () => import('./../views/Create.vue'),
    },
    {
        path: '/providers/edit/:id?',
        name: 'providers.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    }
];
