import {State} from "@/modules/auth/interfaces";

export const currentState = (state: State) => {
    return state.status
}

export const username = (state: State) => {
    return state.user?.name
}

export const companyName = (state: State) => {
    return state.user?.company?.name
}

export const companyId = (state: State) => {
    return state.user?.company?.id
}
