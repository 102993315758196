import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {PurchaseOrderForm} from "@/modules/purchaseOrders/interfaces";
import $ from 'jquery'

export const createPurchaseOrder = async ({commit}: ActionContext<any, any>, purchaseOrder: PurchaseOrderForm) => {
    try {
        await erpApi.post('/purchase_orders', purchaseOrder)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updatePurchaseOrder = async ({commit}: ActionContext<any, any>, purchaseOrder: PurchaseOrderForm) => {
    try {
        await erpApi.put('/purchase_orders/' + purchaseOrder.id, purchaseOrder)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getPurchaseOrder = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/purchase_orders/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getCatalogs = async ({commit}: ActionContext<any, any>, companyId: string) => {
    try {
        const params = $.param({
            filters: [
                {field: 'companyId', value: companyId},
                {field: 'isLeaf', value: 1}
            ]
        });
        const response = await erpApi.get('/purchase_orders/catalogs?' + params)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
