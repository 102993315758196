import {checkAuthStatus} from "@/modules/auth/store/actions";
import {computed} from "vue";
import useCompany from "@/modules/companies/composables/useCompany";
import {companyName} from "@/modules/auth/store/getters";
import store from '@/store';

const useAuth = () => {
    const {createCompany} = useCompany()

    const createUser = async (user: any) => {
        const response = await store.dispatch('auth/createUser', user)

        if (response.ok) {
            await loginUser(user)
            await createCompany({
                id: user.companyId,
                name: user.companyName,
                phone: user.companyPhone,
                address: user.companyAddress,
                status: user.companyStatus
            })
            await loginUser(user)
        }

        return response;
    }

    const loginUser = async (user: any) => {
        return await store.dispatch('auth/signInUser', user)
    }

    const checkAuthStatus = async () => {
        return await store.dispatch('auth/checkAuthStatus')
    }

    const getCompanies = async (username: any) => {
        return await store.dispatch('auth/getCompanies', username)
    }

    const logout = () => {
        store.commit('auth/logout')
    }

    return {
        createUser,
        getCompanies,
        loginUser,
        checkAuthStatus,
        logout,

        authStatus: computed(() => store.getters['auth/currentState']),
        username: computed(() => store.getters['auth/username']),
        companyName: computed(() => store.getters['auth/companyName']),
        companyId: computed<string>(() => (store ? store.getters['auth/companyId'] : ''))
    }
}

export default useAuth;
