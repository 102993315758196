export default [
    {
        path: '/purchase_orders',
        name: 'purchase_orders',
        component: () => import('./../views/List.vue'),
    },
    {
        path: '/purchase_orders/create',
        name: 'purchase_orders.create',
        component: () => import('./../views/Create.vue'),
    },
    {
        path: '/purchase_orders/edit/:id?',
        name: 'purchase_orders.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    }
];
