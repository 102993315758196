import {State, User} from "@/modules/auth/interfaces";

export const loginUser = (state: State, {user, token}: { user: User, token: string }) => {
    localStorage.setItem('token', token);

    state.token = token
    state.user = user
    state.status = 'authenticated'
}

export const logout = (state: State) => {
    localStorage.removeItem('token');

    state.token = null
    state.user = null
    state.status = 'not-authenticated'
}
