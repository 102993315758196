export default [
    {
        path: '/payments',
        name: 'payments',
        component: () => import('./../views/List.vue'),
    },
    {
        path: '/payments/create',
        name: 'payments.create',
        component: () => import('./../views/Create.vue'),
    },
    {
        path: '/payments/edit/:id?',
        name: 'payments.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    }
];
