import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {User} from "@/modules/auth/interfaces";

export const createUser = async ({commit}: ActionContext<any, any>, user: { id: string, name: string, username: string, password: string, passwordConfirmation: string }) => {
    const {id, name, username, password, passwordConfirmation} = user

    try {
        await erpApi.post('/users', {
            id, name, username, password, passwordConfirmation
        })

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const signInUser = async ({commit}: ActionContext<any, any>, user: { id: string, name: string, username: string, password: string, passwordConfirmation: string }) => {
    const {username, password, name} = user

    try {
        const {data} = await erpApi.post('/login', {
            username, password
        })
        const {token} = data;
        const customUser: User = {name, username, company: null}
        commit('loginUser', {user: customUser, token})
        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const checkAuthStatus = async ({commit}: ActionContext<any, any>) => {
    const token = localStorage.getItem('token')

    if (!token) {
        commit('logout')
        return {ok: false, message: 'No hay token'}
    }

    try {
        const {data} = await erpApi.get('/users/lookup')
        const {name, username, company} = data
        const customUser: User = {name, username, company}
        commit('loginUser', {user: customUser, token})

        return {ok: true}
    } catch (error) {
        commit('logout')

        return {ok: false, message: error.response.data.message}
    }

}

export const getCompanies = async ({commit}: ActionContext<any, any>, username: any) => {

    try {
        const {data} = await erpApi.get('/companies/user/' + username)

        const {rows} = data
        const customCompanies = []

        for (const {id, cell: {name}} of rows) {
            customCompanies.push({
                id,
                name
            })
        }

        return {ok: true}
    } catch (error) {
        commit('logout')

        return {ok: false, message: error.response.data.message}
    }
}
