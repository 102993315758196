
import useAuth from "@/modules/auth/composables/useAuth";
import OptionsModal from "@/components/optionsModal.vue";
import { v4 as uuid } from "uuid";

export default {
    components: {OptionsModal},
    setup() {
        const {authStatus, checkAuthStatus} = useAuth()

        checkAuthStatus()

        return {
            authStatus,
            uuid
        }
    }
}
