import axios from "axios";

export const baseURL = process.env.VUE_APP_BASEURL || 'http://localhost:8908';

const erpApi = axios.create({
    baseURL
});

erpApi.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');

    if (token) {
        config.headers.Authorization = 'Bearer ' + token;
    }

    return config;
});

export default erpApi;
