import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {ItemForm} from "@/modules/items/interfaces";
import $ from "jquery";

export const createItem = async ({commit}: ActionContext<any, any>, item: ItemForm) => {
    try {
        await erpApi.post('/items', item)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateItem = async ({commit}: ActionContext<any, any>, item: ItemForm) => {
    try {
        await erpApi.put('/items/' + item.id, item)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getItem = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/items/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getCatalogs = async ({commit}: ActionContext<any, any>, companyId: string) => {
    try {
        const params = $.param({
            filters: [
                {field: 'companyId', value: companyId},
                {field: 'isLeaf', value: 1}
            ]
        });
        const response = await erpApi.get('/items/catalogs?' + params)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
