export default [
    {
        path: '/provider_advances',
        name: 'provider_advances',
        component: () => import('./../views/List.vue'),
    },
    {
        path: '/provider_advances/create',
        name: 'provider_advances.create',
        component: () => import('./../views/Create.vue')
    },
    {
        path: '/provider_advances/edit/:id?',
        name: 'provider_advances.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    }
];
