import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {ItemCategoryForm} from "@/modules/inventory_configuration/interfaces";

export const createItemCategory = async ({commit}: ActionContext<any, any>, itemCategory: ItemCategoryForm) => {
    try {
        await erpApi.post('/item_categories', itemCategory)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateItemCategory = async ({commit}: ActionContext<any, any>, itemCategory: ItemCategoryForm) => {
    try {
        await erpApi.put('/item_categories/' + itemCategory.id, itemCategory)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getItemCategory = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/item_categories/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
