<template>
    <select ref="root" :multiple="!!attr.multiple">
        <slot></slot>
    </select>
</template>

<script>
import {defineComponent, onMounted, onUnmounted, ref, watch} from 'vue'

export default defineComponent({
    props: ['config', 'modelValue', 'attr'],
    setup(props, {emit}) {
        const root = ref(null)
        const contador = ref(0);
        const myConfig = ref({
            minimumInputLength: 0,
            width: '100%'
        });
        const myAjax = ref({
            delay: 700,
            dataType: 'json',
            processResults(data) {
                return {
                    results: data
                };
            },
            data(params) {
                return {
                    q: params.term
                }
            }
        });

        onMounted(() => {
            myConfig.value = window.$.extend(myConfig.value, props.config);

            if (props.config.ajax) {
                myConfig.value.ajax = window.$.extend(myAjax.value, props.config.ajax);
            }

            window.$(root.value)
                // init select2
                .select2(myConfig.value)
                .val(props.modelValue)
                .trigger('change')
                // emit event on change.
                .on('change', function () {
                    emit('update:modelValue', window.$(root.value).val());
                })
        })

        onUnmounted(() => {
            window.$(root.value).off().select2('destroy');
        })

        watch(() => props.modelValue, (value) => {
            const isValidValue = (value) => {
                if (typeof value === 'object' && value.length) return true;
                if (typeof value === 'string' && value.length) return true;
                if (typeof value === 'number' && value) return true;
                return false;
            };

            window.$(root.value).val(value);
            if (window.$(root.value).val() === null && isValidValue(value)) {
                if (props.config.ajax) {
                    let data = {id: value};
                    window.$.ajax({
                        url: props.config.ajax.url(),
                        type: "GET",
                        data,
                        dataType: "json",
                        success(data) {
                            if (!window._.isEmpty(data)) {
                                if (typeof data[0] === 'object') {
                                    window._.forEach(data, (row) => {
                                        let text = typeof row.nombre !== 'undefined' ? row.nombre : row.text;
                                        window.$(root.value).append('<option value="' + row.id + '" selected>' + text + '</option>');
                                    });
                                    setTimeout(function () {
                                        window.$(root.value).val(value).trigger('change');
                                    }, 1000);
                                } else {
                                    let text = typeof data.nombre !== 'undefined' ? data.nombre : data.text;
                                    window.$(root.value).append('<option value="' + data.id + '" selected>' + text + '</option>');

                                    setTimeout(function () {
                                        window.$(root.value).val(data.id != '' ? value : '').trigger('change');
                                    });
                                }
                            }
                        }
                    });
                } else {
                    console.log(
                        window.$(root.value).prop('name'),
                        window.$(root.value).prop('id'),
                        value,
                        "Error when it try set value. The option do not exists."
                    );
                }
            } else {
                const aux = window.$(root.value).val();

                if (Array.isArray(aux) && aux.length === value.length && contador.value > 0) {
                    contador.value = 0;
                    return;
                }

                contador.value += 1;
                window.$(root.value).val(value).trigger('change');
            }
        })

        return {
            root
        }
    },
})
</script>

<style lang="sass">
@import "./../assets/css/select2.min.css"
</style>
