import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {AccountingCenterForm} from "@/modules/accountingCenters/interfaces";

export const createAccountingCenter = async ({commit}: ActionContext<any, any>, accountingCenter: AccountingCenterForm) => {
    try {
        await erpApi.post('/accounting_centers', accountingCenter)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateAccountingCenter = async ({commit}: ActionContext<any, any>, accountingCenter: AccountingCenterForm) => {
    try {
        await erpApi.put('/accounting_centers/' + accountingCenter.id, accountingCenter)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getAccountingCenter = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/accounting_centers/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
