export default [
    {
        path: '/companies',
        name: 'companies',
        component: () => import('./../views/List.vue')
    },

    {
        path: '/companies/create',
        name: 'companies.create',
        component: () =>import('./../views/Create.vue')
    },
    {
        path: '/companies/edit/:id?',
        name: 'companies.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    }
];
