import {createRouter, createWebHistory, RouteRecordRaw} from 'vue-router'
import authRouter from './../modules/auth/router'
import locationsRouter from './../modules/locations/router'
import accountingCentersRouter from './../modules/accountingCenters/router'
import providersRouter from './../modules/providers/router'
import accountingAccountsRouter from './../modules/accountingAccounts/router'
import pettyCashRouter from './../modules/pettyCash/router'
import companiesRouter from './../modules/companies/router'
import providerAdvancesRouter from './../modules/providerAdvances/router'
import payments from './../modules/payments/router'
import purchaseInvoices from './../modules/purchaseInvoices/router'
import purchaseOrders from './../modules/purchaseOrders/router'
import inventory_configuration from './../modules/inventory_configuration/router'
import inventoryEntries from './../modules/inventoryEntries/router'
import accounting_configuration from './../modules/accounting_configuration/router'
import items from './../modules/items/router'
import isAuthenticatedGuard from "@/modules/auth/router/auth-guard";
import isNotAuthenticated from "@/modules/auth/router/is-not-auth";

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: () => import('@/layouts/MainLayout.vue'),
        beforeEnter: [isAuthenticatedGuard],
        children: [
            ...locationsRouter,
            ...accountingCentersRouter,
            ...providersRouter,
            ...accountingAccountsRouter,
            ...pettyCashRouter,
            ...companiesRouter,
            ...providerAdvancesRouter,
            ...payments,
            ...purchaseInvoices,
            ...purchaseOrders,
            ...inventory_configuration,
            ...inventoryEntries,
            ...accounting_configuration,
            ...items
        ]
    },
    {
        path: '/auth',
        beforeEnter: [isNotAuthenticated],
        ...authRouter
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
