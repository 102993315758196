import './bootstrap';

import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

//general components
import Select2 from './components/Select2.vue';
import Date from './components/Date.vue';
import CurrencyInput from "./components/CurrencyInput.vue";

// declare global {
//     interface Window {
//         $: (e: string, t?: any) => any
//     }
// }
//
// window.$ = window.$ || {};

createApp(App)
    .use(store)
    .use(router)
    .component('select2', Select2)
    .component('date', Date)
    .component('currency-input', CurrencyInput)
    .mount('#app')
