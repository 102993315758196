import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {InventoryEntryForm} from "@/modules/inventoryEntries/interfaces";
import $ from "jquery";

export const createInventoryEntry = async ({commit}: ActionContext<any, any>, inventoryEntry: InventoryEntryForm) => {
    try {
        await erpApi.post('/inventory_entries', inventoryEntry)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateInventoryEntry= async ({commit}: ActionContext<any, any>, inventoryEntry: InventoryEntryForm) => {
    try {
        await erpApi.put('/inventory_entries/' + inventoryEntry.id, inventoryEntry)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getInventoryEntry = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/inventory_entries/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getCatalogs = async ({commit}: ActionContext<any, any>, companyId: string) => {
    try {
        const params = $.param({
            filters: [
                {field: 'companyId', value: companyId},
                {field: 'isLeaf', value: 1}
            ]
        });
        const response = await erpApi.get('/inventory_entries/catalogs?' + params)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
