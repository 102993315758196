export default [
    {
        path: '/accounting_configuration',
        name: 'accounting_configuration',
        component: () => import('./../views/List.vue'),
        children: [
            {
                path: '/accounting_configuration/taxes',
                name: 'accounting_configuration.taxes',
                component: () => import('./../views/Taxes.vue'),
            }
        ]
    }
];
