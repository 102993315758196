import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {PurchaseInvoiceForm} from "@/modules/purchaseInvoices/interfaces";
import $ from 'jquery'

export const createPurchaseInvoice = async ({commit}: ActionContext<any, any>, purchaseInvoice: PurchaseInvoiceForm) => {
    try {
        await erpApi.post('/purchase_invoices', purchaseInvoice)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updatePurchaseInvoice = async ({commit}: ActionContext<any, any>, purchaseInvoice: PurchaseInvoiceForm) => {
    try {
        await erpApi.put('/purchase_invoices/' + purchaseInvoice.id, purchaseInvoice)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getPurchaseInvoice = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/purchase_invoices/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getCatalogs = async ({commit}: ActionContext<any, any>, companyId: string) => {
    try {
        const params = $.param({
            filters: [
                {field: 'companyId', value: companyId},
                {field: 'isLeaf', value: 1}
            ]
        });
        const response = await erpApi.get('/purchase_invoices/catalogs?' + params)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
