import {Ref, ref} from "vue";
import {v4 as uuid} from "uuid";
import {Company} from "@/modules/companies/interfaces";
import store from '@/store';

const defaultValues = (): Company => {
    return {
        id: uuid(),
        name: "",
        phone: "",
        address:"",
        status: "active"
    }
}

const companyForm: Ref<Company> = ref(defaultValues())

const useCompany = () => {

    const catalogs = {
        status: [
            {id: 'active', title: 'Activo'},
            {id: 'inactive', title: 'Inactivo'},
        ],
        type: [
            {id: '1', title: '1'},
            {id: '2', title: '2'},
        ]
    };
    const clearForm = () => companyForm.value = defaultValues()

    const createCompany = async (company: Company) => {
        return await store.dispatch('companies/createCompany', company)
    }

    const updateCompany = async (companyForm: Company) => {
        return await store.dispatch('companies/updateCompany', companyForm)
    }

    const getCompany = async (id: any) => {
        const {ok, data}  = await store.dispatch('companies/getCompany', id)

        return data
    }

    const getCompanies = async (username: any) => {
        return await store.dispatch('companies/getCompanies', username)
    }

    return {
        companyForm,
        catalogs,
        clearForm,
        createCompany,
        updateCompany,
        getCompany,
        getCompanies
    }
}

export default useCompany
