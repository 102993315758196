import * as taxesActions from './taxesActions'

const accountingConfigurationModule = {
    namespaced: true,
    actions: {
        ...{},
        ...taxesActions
    }
};

export default accountingConfigurationModule
