<template>
    <input
        ref="inputRef"
        type="text"
        :value="formattedValue"
    >
</template>

<script>
import {watch} from "vue";
import {useCurrencyInput} from 'vue-currency-input'

export default {
    props: {
        modelValue: Number, // Vue 2: value
        options: Object
    },

    setup(props) {
        const {formattedValue, inputRef, setValue} = useCurrencyInput(props.options)

        watch(() => props.modelValue, (value) => { // Vue 2: props.value
            setValue(Number(value) || 0)
        })

        return {inputRef, formattedValue}
    }
}
</script>
