export default [
    {
        path: '/inventory_entries',
        name: 'inventory_entries',
        component: () => import('./../views/List.vue'),
    },
    {
        path: '/inventory_entries/edit/:id?',
        name: 'inventory_entries.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    }
];
