import * as itemCategoriesActions from './itemCategoriesActions'
import * as measurementUnitActions from './measurementUnitActions'

const locationModule = {
    namespaced: true,
    actions: {
        ...{},
        ...itemCategoriesActions,
        ...measurementUnitActions
    }
};

export default locationModule
