export default [
    {
        path: '/purchase_invoices',
        name: 'purchase_invoices',
        component: () => import('./../views/List.vue'),
    },
    {
        path: '/purchase_invoices/create',
        name: 'purchase_invoices.create',
        component: () => import('./../views/Create.vue'),
    },
    {
        path: '/purchase_invoices/edit/:id?',
        name: 'purchase_invoices.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    }
];
