import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {ProviderForm} from "@/modules/providers/interfaces";
import {AccountingCenterForm} from "@/modules/accountingCenters/interfaces";

export const createProvider = async ({commit}: ActionContext<any, any>, provider: ProviderForm) => {
    try {
        await erpApi.post('/providers', provider)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateProvider = async ({commit}: ActionContext<any, any>, provider: ProviderForm) => {
    try {
        await erpApi.put('/providers/' + provider.id, provider)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getProvider = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/providers/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
