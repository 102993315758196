import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {PettyCashForm, TransferBetweenForm, TransferForm} from "@/modules/pettyCash/interfaces"

export const createPettyCash = async ({commit}: ActionContext<any, any>, pettyCash: PettyCashForm) => {
    try {
        await erpApi.post('/petty_cash', pettyCash)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const createTransfer = async ({commit}: ActionContext<any, any>, transfer: TransferForm) => {
    try {
        await erpApi.post(`/petty_cash/cash_transfers`, transfer)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const createTransferBetween = async ({commit}: ActionContext<any, any>, transfer: TransferBetweenForm) => {
    try {
        await erpApi.post(`/transfers_between`, transfer)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updatePettyCash = async ({commit}: ActionContext<any, any>, pettyCash: PettyCashForm) => {
    try {
        console.log(pettyCash)
        await erpApi.put('/petty_cash/' + pettyCash.id, pettyCash)
        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getPettyCash = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/petty_cash/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getTransfer = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/petty_cash/cash_transfers/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getTransferBetween = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/transfers_between/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
