export default [
    {
        path: '/petty_cash',
        name: 'petty_cash',
        component: () => import('./../views/List.vue'),
    },
    {
        path: '/petty_cash/create',
        name: 'petty_cash.create',
        component: () => import('./../views/Create.vue'),
    },
    {
        path: '/petty_cash/:id?/cash_transfers',
        name: 'petty_cash.create_transfer',
        component: () => import('./../views/CreateTransfer.vue'),
        props: true
    },
    {
        path: '/petty_cash/:id?/cash_transfers_between',
        name: 'petty_cash.create_transfer_between',
        component: () => import('./../views/CreateTransferBetween.vue'),
        props: true
    },
    {
        path: '/petty_cash/edit/:id?',
        name: 'petty_cash.edit',
        component: () => import('./../views/Edit.vue'),
        props: true
    },
    {
        path: '/petty_cash/:id?/cash_transfers/:transferId?',
        name: 'petty_cash.edit_transfer',
        component: () => import('./../views/EditTransfer.vue'),
        props: true
    },
    {
        path: '/petty_cash/:id?/cash_transfers_between/:transferId?',
        name: 'petty_cash.edit_transfer_between',
        component: () => import('./../views/EditTransferBetween.vue'),
        props: true
    },
];
