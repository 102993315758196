import useAuth from "../modules/auth/composables/useAuth";

window._ = require('lodash');

//ajax jquery
window.$.ajaxSetup({
    beforeSend(xhr, settings) {
        const {companyId} = useAuth();
        const token = 'Bearer ' + localStorage.getItem('token');

        xhr.setRequestHeader('Authorization', token);
        settings.data = window.$.extend(settings.data, {companyId: companyId.value});

        settings.url = settings.url.includes('?')
            ? settings.url + '&companyId=' + companyId.value
            : settings.url + '?companyId=' + companyId.value

        return true;
    },
});

//jqgrid jquery
window.$.extend(window.$.jgrid.defaults, {
    loadBeforeSend(jqXHR) {
        const token = 'Bearer ' + localStorage.getItem('token');
        jqXHR.setRequestHeader('Authorization', token);
    }
});
