import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {AccountingAccountForm} from "@/modules/accountingAccounts/interfaces";

export const createAccountingAccount = async ({commit}: ActionContext<any, any>, accountingAccount: AccountingAccountForm) => {
    try {
        await erpApi.post('/accounting_accounts', accountingAccount)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateAccountingAccount = async ({commit}: ActionContext<any, any>, accountingAccount: AccountingAccountForm) => {
    try {
        await erpApi.put('/accounting_accounts/' + accountingAccount.id, accountingAccount)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getAccountingAccount = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/accounting_accounts/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
