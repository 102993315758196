import store from "@/store";
import router from "@/router";

const isNotAuthenticated = async (to: any, from: any, next: any) => {
    const {ok} = await store.dispatch('auth/checkAuthStatus')

    if ((to.name === 'login' || to.name === 'register') && ok) {
        router.push('/')
    } else {
        next()
    }
}


export default isNotAuthenticated