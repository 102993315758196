import {ActionContext} from "vuex";
import {Company} from "@/modules/companies/interfaces";
import erpApi from "@/api/erpApi";

export const createCompany = async ({commit}: ActionContext<any, any>, company: Company) => {
    const {id, name, phone, address, status} = company

    try {
        await erpApi.post('/companies', {
            id, name, phone, address, status
        })
        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateCompany = async ({commit}: ActionContext<any, any>, company: Company) => {
    try {
        await erpApi.put('/companies/' + company.id, company)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getCompany = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/companies/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getCompanies = async ({commit}: ActionContext<any, any>, username: any) => {

    try {
        await erpApi.get('/companies/user/' + username)

        return {ok: true}
    } catch (error) {
        commit('logout')

        return {ok: false, message: error.response.data.message}
    }
}
