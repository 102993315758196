import {ActionContext} from "vuex";
import erpApi from "@/api/erpApi";
import {MeasurementUnitForm} from "@/modules/inventory_configuration/interfaces";

export const createMeasurementUnit = async ({commit}: ActionContext<any, any>, measurementUnitForm: MeasurementUnitForm) => {
    try {
        await erpApi.post('measurement_units', measurementUnitForm)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const updateMeasurementUnit = async ({commit}: ActionContext<any, any>, measurementUnitForm: MeasurementUnitForm) => {
    try {
        await erpApi.put('/measurement_units/' + measurementUnitForm.id, measurementUnitForm)

        return {ok: true}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}

export const getMeasurementUnit = async ({commit}: ActionContext<any, any>, id: string) => {
    try {
        const response = await erpApi.get('/measurement_units/' + id)

        return {ok: true, data: response.data}
    } catch (error) {
        return {ok: false, message: error.response.data.message}
    }
}
